<script setup lang="ts">
import { mdiAccountCircle, mdiChatQuestionOutline } from "@mdi/js";
import { VNavigationDrawer } from "vuetify/components/VNavigationDrawer";
import { useCrisp } from "@mono/ui/composables/useCrisp";
import { useAuthStore } from "@/stores/auth";

const { userSidebarLinks, orgSidebarLinks } = useAppSidebarLinks();
const authStore = useAuthStore();
const { t } = useI18n();
const { isLoggedIn } = useAuthStorage();
const { crisp } = useCrisp();

const sidebar = defineModel<boolean>({
  required: true,
});
</script>

<template>
  <VNavigationDrawer
    v-if="isLoggedIn"
    v-model="sidebar"
    mobileBreakpoint="xl"
  >
    <div class="scrollbar-hidden">
      <MList>
        <ListItemLink
          :route="{
            name: 'dashboard-account',
          }"
          :prependIcon="mdiAccountCircle"
        >
          <template #title>
            {{ authStore.userDetails?.fullName }}
          </template>
          <template #subtitle>
            <div class="text-xs">
              {{ authStore.userDetails?.email }}
            </div>
          </template>
        </ListItemLink>
      </MList>
      <MDivider />
      <MList v-if="orgSidebarLinks === undefined">
        <ListItemLink
          v-for="link in userSidebarLinks"
          :key="link.to.name"
          :route="link.to"
          :prependIcon="link.icon"
          :title="link.title"
        />
      </MList>
      <MList v-else>
        <template
          v-for="(group, groupKey) in orgSidebarLinks"
          :key="groupKey"
        >
          <MListGroup
            v-if="'links' in group && group.links && group.links.length > 0"
            v-show="group.renderGuard()"
          >
            <template #activator="{ props: slotProps }">
              <MListItem
                v-bind="slotProps"
                :title="group.title"
              />
            </template>
            <ListItemLink
              v-for="(link, index) in group.links"
              v-show="link.renderGuard()"
              :key="index"
              :route="link.to"
              :prependIcon="link.icon"
              :title="link.title"
            />
          </MListGroup>

          <ListItemLink
            v-if="'to' in group"
            v-show="group.renderGuard()"
            :route="group.to"
            :prependIcon="group.icon"
            :title="group.title"
          />
        </template>
      </MList>
    </div>
    <template #append>
      <MDivider />
      <MList>
        <MListItem
          :prependIcon="mdiChatQuestionOutline"
          :title="t('help-center')"
          @click="crisp.chatToggle()"
        />
        <AppSettings isListItem />
      </MList>
      <MDivider />
      <div class="my-12"></div>
    </template>
  </VNavigationDrawer>
</template>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none !important;
}
.v-navigation-drawer__content {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
