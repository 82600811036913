import {
  mdiAccount,
  mdiAccountMultiple,
  mdiAccountMultipleOutline,
  mdiAccountOutline,
  mdiCalendarStart,
  mdiChartAreaspline,
  mdiChartPie,
  mdiCog,
  mdiCogClockwise,
  mdiCreditCard,
  mdiDomain,
  mdiFilePercent,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiLabel,
  mdiMagnifyScan,
  mdiPackageVariantClosed,
  mdiSitemap,
  mdiTextBoxMultiple,
  mdiTransfer,
  mdiChartAreasplineVariant,
  mdiFormatListBulletedSquare,
  mdiCashPlus,
  mdiCashMinus,
  mdiCash,
  mdiPackageVariantClosedCheck,
  mdiPackageVariantPlus,
  mdiCashMultiple,
  mdiTextBoxMultipleOutline,
} from "@mdi/js";
import type { RoutesNamedLocations } from "@typed-router";

type LinkSimple = {
  title: string;
  icon: string;
  to: RoutesNamedLocations;
};
type Link = {
  title: string;
  icon: string;
  to: RoutesNamedLocations;
  renderGuard: () => boolean;
};
type LinkGroup = {
  title: string;
  icon?: string;
  renderGuard: () => boolean;
  links: Link[];
};

export const useAppSidebarLinks = () => {
  const i18n = useI18n();

  const userSidebarLinks = computed<LinkSimple[]>(() => [
    {
      title: i18n.t("organizations"),
      icon: mdiDomain,
      to: {
        name: "dashboard",
      },
    },
    {
      title: i18n.t("payment-methods"),
      icon: mdiCreditCard,
      to: {
        name: "dashboard-billing-methods",
      },
    },
    {
      title: i18n.t("subscriptions"),
      icon: mdiCogClockwise,
      to: {
        name: "dashboard-billing-subscriptions",
      },
    },
  ]);
  const orgSidebarLinks = computed<
    Record<string, LinkGroup | Link> | undefined
  >(() => {
    const orgId = useRoute("dashboard-organizations-orgId").params.orgId;
    const isOrgDashboardRoute =
      useRoute("dashboard").name?.startsWith("dashboard-organizations-orgId") &&
      orgId !== undefined;
    if (!isOrgDashboardRoute) {
      return undefined;
    }

    const { checkPermission, checkRole } = useAuthorization(orgId);

    return {
      sales: {
        title: i18n.t("sales"),
        renderGuard: () => checkPermission({ rule: "sales.isEnabled" }),
        links: [
          {
            title: i18n.t("customers-list"),
            icon: mdiAccountMultipleOutline,
            to: {
              name: "dashboard-organizations-orgId-sales-customers",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "sales.customers.view" }),
          },
          {
            title: i18n.t("sales-quotations"),
            icon: mdiTextBoxMultipleOutline,
            to: {
              name: "dashboard-organizations-orgId-sales-salesquotations",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({
                rule: "sales.salesQuotations.view",
              }),
          },
          {
            title: i18n.t("sales-invoices"),
            icon: mdiTextBoxMultipleOutline,
            to: {
              name: "dashboard-organizations-orgId-sales-invoices",
              params: { orgId },
            },
            renderGuard: () => checkPermission({ rule: "sales.invoices.view" }),
          },
          {
            title: i18n.t("sales-receipts"),
            icon: mdiTextBoxMultipleOutline,
            to: {
              name: "dashboard-organizations-orgId-sales-receipts",
              params: { orgId },
            },
            renderGuard: () => checkPermission({ rule: "sales.receipts.view" }),
          },
          {
            title: i18n.t("credit-notes"),
            icon: mdiTextBoxMultipleOutline,
            to: {
              name: "dashboard-organizations-orgId-sales-creditnotes",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "sales.creditNotes.view" }),
          },
          {
            title: i18n.t("sales-pricing-policies"),
            icon: mdiCashMultiple,
            to: {
              name: "dashboard-organizations-orgId-sales-pricingpolicies",
              params: { orgId },
            },
            renderGuard: () => true,
          },
        ],
      },
      purchasing: {
        title: i18n.t("purchasing"),
        renderGuard: () => checkPermission({ rule: "purchasing.isEnabled" }),
        links: [
          {
            title: i18n.t("vendors-list"),
            icon: mdiAccountMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-vendors",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "purchasing.vendors.view" }),
          },
          {
            title: i18n.t("purchase-orders"),
            icon: mdiTextBoxMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-purchaseorders",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "purchasing.purchaseOrders.view" }),
          },
          {
            title: i18n.t("purchasing-invoices"),
            icon: mdiTextBoxMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-invoices",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "purchasing.invoices.view" }),
          },
          {
            title: i18n.t("purchasing-receipts"),
            icon: mdiTextBoxMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-receipts",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "purchasing.receipts.view" }),
          },
          {
            title: i18n.t("debit-notes"),
            icon: mdiTextBoxMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-debitnotes",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "purchasing.debitNotes.view" }),
          },
          {
            title: i18n.t("purchasing-pricing-policies"),
            icon: mdiCashMultiple,
            to: {
              name: "dashboard-organizations-orgId-purchasing-pricingpolicies",
              params: { orgId },
            },
            renderGuard: () => true,
          },
        ],
      },
      inventory: {
        title: i18n.t("products-and-invento"),
        icon: mdiPackageVariantClosed,
        renderGuard: () => checkPermission({ rule: "products.isEnabled" }),
        links: [
          {
            title: i18n.t("product-categories"),
            icon: mdiLabel,
            to: {
              name: "dashboard-organizations-orgId-inventory-categories",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "products.productCategories.view" }),
          },
          {
            title: i18n.t("products-list"),
            icon: mdiFormatListNumbered,
            to: {
              name: "dashboard-organizations-orgId-inventory-products",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "products.products.view" }),
          },
          {
            title: i18n.t("inventory-stores"),
            icon: mdiPackageVariantClosed,
            to: {
              name: "dashboard-organizations-orgId-inventory-stores",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "products.inventoryStores.view" }),
          },
          {
            title: i18n.t("stock-takes"),
            icon: mdiMagnifyScan,
            to: {
              name: "dashboard-organizations-orgId-inventory-stocktakes",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "products.inventoryStocktakes.view" }),
          },
          {
            title: i18n.t("inventory-transfers"),
            icon: mdiTransfer,
            to: {
              name: "dashboard-organizations-orgId-inventory-transfers",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "products.inventoryTransfers.view" }),
          },
        ],
      },
      fixedassets: {
        title: i18n.t("fixed-assets"),
        icon: mdiFormatListNumbered,
        renderGuard: () =>
          checkPermission({
            rule: "assets.isEnabled",
          }),
        links: [
          {
            title: i18n.t("assets"),
            icon: mdiFormatListNumbered,
            to: {
              name: "dashboard-organizations-orgId-fixedassets-assets",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "assets.fixedAssets.view" }),
          },
        ],
      },
      accounting: {
        title: i18n.t("advanced-accounting"),
        icon: mdiSitemap,
        renderGuard: () => checkPermission({ rule: "accounting.isEnabled" }),
        links: [
          {
            title: i18n.t("chart-of-accounts"),
            icon: mdiSitemap,
            to: {
              name: "dashboard-organizations-orgId-accounting-accounts",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({ rule: "accounting.accounts.view" }),
          },
          {
            title: i18n.t("manual-journals"),
            icon: mdiFormatListNumbered,
            to: {
              name: "dashboard-organizations-orgId-accounting-journal",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({
                rule: "accounting.manualJournals.view",
              }),
          },
          {
            title: i18n.t("general-receipts"),
            icon: mdiTextBoxMultiple,
            to: {
              name: "dashboard-organizations-orgId-accounting-receipts",
              params: { orgId },
            },
            renderGuard: () =>
              checkPermission({
                rule: "accounting.generalReceipts.view",
              }),
          },
          {
            title: i18n.t("opening-balance"),
            icon: mdiCalendarStart,
            to: {
              name: "dashboard-organizations-orgId-accounting-opening",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("reporting-dimensions"),
            icon: mdiChartPie,
            to: {
              name: "dashboard-organizations-orgId-accounting-dimensions",
              params: { orgId },
            },
            renderGuard: () => checkRole("isManager"),
          },
        ],
      },
      reports: {
        title: i18n.t("reports"),
        icon: mdiChartAreaspline,
        renderGuard: () => checkPermission({ rule: "reports.isEnabled" }),
        links: [
          {
            title: i18n.t("transactions-history"),
            icon: mdiFormatListBulleted,
            to: {
              name: "dashboard-organizations-orgId-reports-transactionshistory",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("balance-sheet"),
            icon: "custom:BalanceSheetIcon",
            to: {
              name: "dashboard-organizations-orgId-reports-balancesheet",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("income-statement"),
            icon: "custom:IncomeStatementIcon",
            to: {
              name: "dashboard-organizations-orgId-reports-incomestatement",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("trial-balance"),
            icon: "custom:BalanceSheetIcon",
            to: {
              name: "dashboard-organizations-orgId-reports-trialbalance",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("account-statement"),
            icon: mdiFormatListBulleted,
            to: {
              name: "dashboard-organizations-orgId-reports-accountstatement",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("vat-report"),
            icon: mdiFilePercent,
            to: {
              name: "dashboard-organizations-orgId-reports-saudivatreport",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("customer-statement"),
            icon: mdiAccountOutline,
            to: {
              name: "dashboard-organizations-orgId-reports-customerstatement",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("vendor-statement"),
            icon: mdiAccount,
            to: {
              name: "dashboard-organizations-orgId-reports-vendorstatement",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("inventory-ledger"),
            icon: mdiPackageVariantPlus,
            to: {
              name: "dashboard-organizations-orgId-reports-inventoryledger",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("current-inventory"),
            icon: mdiPackageVariantClosedCheck,
            to: {
              name: "dashboard-organizations-orgId-reports-currentinventory",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-summary"),
            icon: mdiCashPlus,
            to: {
              name: "dashboard-organizations-orgId-reports-salesinvoices",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-receipt-summary"),
            icon: mdiCashPlus,
            to: {
              name: "dashboard-organizations-orgId-reports-salesreceipts",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("purchasing-summary"),
            icon: mdiCashMinus,
            to: {
              name: "dashboard-organizations-orgId-reports-purchasinginvoices",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("purchasing-receipt-summary"),
            icon: mdiCashMinus,
            to: {
              name: "dashboard-organizations-orgId-reports-purchasingreceipts",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("general-receipt-summary"),
            icon: mdiCash,
            to: {
              name: "dashboard-organizations-orgId-reports-generalreceipts",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("receivables-aging"),
            icon: mdiFormatListBulletedSquare,
            to: {
              name: "dashboard-organizations-orgId-reports-salesinvoicesaging",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("payables-aging"),
            icon: mdiFormatListBulletedSquare,
            to: {
              name: "dashboard-organizations-orgId-reports-purchasinginvoicesaging",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-total-by-team-member"),
            icon: mdiChartAreasplineVariant,
            to: {
              name: "dashboard-organizations-orgId-reports-salestotalbyteammember",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-total-by-store"),
            icon: mdiChartAreasplineVariant,
            to: {
              name: "dashboard-organizations-orgId-reports-salestotalbystore",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-total-by-pos"),
            icon: mdiChartAreasplineVariant,
            to: {
              name: "dashboard-organizations-orgId-reports-salestotalbypos",
              params: { orgId },
            },
            renderGuard: () => true,
          },
          {
            title: i18n.t("sales-total-by-products"),
            icon: mdiChartAreasplineVariant,
            to: {
              name: "dashboard-organizations-orgId-reports-salestotalbyproducts",
              params: { orgId },
            },
            renderGuard: () => true,
          },
        ],
      },
      settings: {
        title: i18n.t("organization-settings"),
        renderGuard: () => checkRole("isManager"),
        links: [],
        to: {
          name: "dashboard-organizations-orgId-settings-details",
          params: { orgId },
        },
        icon: mdiCog,
      },
    };
  });

  return {
    userSidebarLinks,
    orgSidebarLinks,
  };
};
